import { Application } from "@hotwired/stimulus"
import "preline/dist/preline.js"
import Rails from '@rails/ujs'

const application = Application.start();
application.debug = false
window.Stimulus = application

Rails.start()

export { application }