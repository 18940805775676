import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import { toggle } from 'el-transition'

application.register("mobile-menu", class extends Controller {
  static targets = ["container", "backdrop", "menu", "menuInner", "close"]

  toggleBackdrop() {
    this.containerTarget.style.display = "block";
    toggle(this.containerTarget)
    toggle(this.backdropTarget)
    toggle(this.menuTarget)
    toggle(this.menuInnerTarget)
    toggle(this.closeTarget)
  }
});