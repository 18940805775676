import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import $ from "jquery"

application.register("cell", class extends Controller {
  static targets = ["label", "input", "edit"]

  initialize() {
    this.config = JSON.parse(this.element.dataset.config)
    const controller = this

    if(!this.config.has_details) {
      $(this.element).closest(".ag-cell").on("dblclick", function() {
        controller.displayEditInput()
      })
    }
  }

  preventSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    return false
  }

  displayEditInput() {
    $(this.labelTarget).hide();
    $(this.editTarget).hide();
    let inputValue = this.inputTarget.value;
    $(this.inputTarget).show().trigger("focus").val("").val(inputValue).select();
  }

  hideEditInput() {
    $(this.inputTarget).hide();
    $(this.labelTarget).show();
    $(this.editTarget).show();
  }

  toggleEditInput(e) {
    if(e.key == "Escape" || e.key == "Enter") {
      const cell = this;
      e.preventDefault();
      if(e.key == "Enter") {
        let $form = $(this.element).closest("form")
        let gridController = application.getControllerForElementAndIdentifier(window.document.getElementById("grid"), "grid");
        $.post(`${$form.attr("action")}.json`, `${$(this.element).closest(".ag-row").find(":input").serialize()}&${$.param({_method: "patch", authenticity_token: $("meta[name=csrf-token]").attr("content")})}`, function(response) {
          $(cell.labelTarget).text(cell.inputTarget.value);
          cell.hideEditInput();
          let node = gridController.gridApi.getRowNode(cell.config.row_id)
          let data  = node.data[cell.config.column_key]
          data.value = cell.inputTarget.value
          node.setDataValue(cell.config.column_id.toString(), data)
        }).fail(function() {

        })
      }

      this.hideEditInput();
    }
  }
});