import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import $ from "jquery"

application.register("column-header", class extends Controller {
  static targets = ["label", "input", "actionButton"]

  initialize() {
    const controller = this

    if(this.element.dataset.editable) {
      $(this.element).closest(".ag-header-cell-comp-wrapper").on("dblclick", function() {
        controller.displayEditInput()
      })
    }
  }

  preventSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    return false
  }

  displayEditInput() {
    $(this.labelTarget).hide();
    $(this.actionButtonTargets).hide();
    let inputValue = this.inputTarget.value;
    $(this.inputTarget).show().trigger('focus').val('').val(inputValue).select();
  }

  hideEditInput() {
    $(this.inputTarget).hide();
    $(this.labelTarget).show();
    $(this.actionButtonTargets).show();
  }

  toggleEditInput(e) {
    const controller = this

    if(e.key == "Escape" || e.key == "Enter") {
      e.preventDefault();
      if(e.key == "Enter") {
        let $form = $(this.element).closest("form")
        let gridController = application.getControllerForElementAndIdentifier(window.document.getElementById("grid"), "grid");

        $.post(`${$form.attr("action")}.json`, `${$(this.element).find(":input").serialize()}&${$.param({_method: "patch", authenticity_token: $("meta[name=csrf-token]").attr("content")})}`, function(response) {
          let columns = gridController.columns;
          columns.forEach(function(col) {
            if(col.colId == controller.element.dataset.id) {
              col.headerName = controller.inputTarget.value
            }
          });

          controller.labelTarget.textContent = controller.inputTarget.value;

          gridController.gridApi.setGridOption("columnDefs", columns);
        }).fail(function() {

        })
      }
      this.hideEditInput();
    }
  }
});