import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"

application.register("email-template", class extends Controller {
  static targets = ["submitButton", "textEditor", "contentHiddenInput"]

  submit(e) {
    e.preventDefault()

    this.contentHiddenInputTarget.value = application.getControllerForElementAndIdentifier(this.textEditorTarget, "tiptap-editor").editor.getHTML()

    this.element.submit()
  }
});