import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import $ from "jquery"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import * as agGrid from "ag-grid-community"

application.register("grid", class extends Controller {
  static targets = ["container", "actionButtons"]
  static values = {
    columns: Array,
    rows: Array,
    addNewColumnUrl: String,
    addNewRowUrl: String
  }

  initialize() {
    const grid = this;
    this.$form = $(this.element).closest("form")
    this.columns = this.columnsValue.map(function(config) {
      config.cellRenderer = CustomCell
      return config
    });
    this.rows = this.rowsValue
    const gridOptions = {
      rowData: [],
      columnDefs: this.columns,
      rowData: this.rows,
      domLayout: "autoHeight",
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      components: {
        agColumnHeader: CustomHeader,
      },
      getRowId: function(params) {
        return params.data.row_id
      },
      onGridReady: function(e) {
        e.api.forEachNode(function(node) {
          grid._initNodeEvents(node)
        });
      },
      onColumnMoved: function(e) {
        if(e.finished) {
          $.each(e.api.getAllDisplayedColumns(), function(index, column) {
            let $column = grid.$form.find(`.column-header[data-id=${column.colId}]`)
            $column.find(`input[type="hidden"][name*=column_order_position]`)
                   .val(index)
            $.post(`${grid.$form.attr("action")}.json`, `${$($column).find(":input").serialize()}&${$.param({_method: "patch", authenticity_token: $("meta[name=csrf-token]").attr("content")})}`, function(response) {

            })
          });
        }
      },
      onSelectionChanged: function(e) {
        $("body").trigger("grid.selectionChanged", [e.api.getSelectedRows()])
      }
    };

    this.gridApi = agGrid.createGrid(this.containerTarget, gridOptions);
  }

  addNewColumn(e) {
    e.preventDefault();
    const controller = this

    $.post(this.addNewColumnUrlValue, {
      authenticity_token: $("meta[name=csrf-token]").attr("content")
    }, function(response) {
      controller.columns.push(response.data)
      controller.gridApi.setGridOption("columnDefs", controller.columns)
      setTimeout(function() {
        $(controller.element).find(`.column-header[data-id="${response.data.colId}"] .edit`).click();
      }, 100);
    }).fail(function() {
    })

    return false;
  }

  deleteColumn(e) {
    e.preventDefault();

    const grid = this;
    let colId = e.currentTarget.dataset.id

    $.post(`/columns/${colId}`, {
      _method: "delete",
      authenticity_token: $("meta[name=csrf-token]").attr("content")
    }, function() {
      let columns = grid.columns.filter(function(column) { return column.colId != colId });

      grid.gridApi.setGridOption("columnDefs", columns);
    })
  }

  addNewRow(e) {
    e.preventDefault();

    const grid = this;

    $.post(this.addNewRowUrlValue, {
      authenticity_token: $("meta[name=csrf-token]").attr("content")
    }, function(response) {
      let addedNode = grid.gridApi.applyTransaction({
        add: [response.data]
      }).add[0]

      grid._initNodeEvents(addedNode)
    }).fail(function() {
    })

    return false;
  }

  deleteRow(e) {
    e.preventDefault();

    const grid = this;
    let node = this.gridApi.getRowNode($(e.currentTarget).data("node-id"))
    let actionButtons = this.actionButtonsTargets.filter(function(el) {
      return $(el).data("node-id") == $(e.currentTarget).data("node-id")
    })

    $.post(`/rows/${node.data.row_id}`, {
      _method: "delete",
      authenticity_token: $("meta[name=csrf-token]").attr("content")
    }, function() {
      $(actionButtons).remove();
      grid.gridApi.applyTransaction({
        remove: [node.data],
      })
    })
  }

  duplicateRow(e) {
    e.preventDefault();

    const grid = this;
    let node = this.gridApi.getRowNode($(e.currentTarget).data("node-id"))

    $.post(this.addNewRowUrlValue, {
      authenticity_token: $("meta[name=csrf-token]").attr("content"),
      data: node.data
    }, function(response) {
      let addedNode = grid.gridApi.applyTransaction({
        add: [response.data],
        addIndex: node.rowIndex + 1
      }).add[0]

      grid._initNodeEvents(addedNode)
    }).fail(function() {
    })
  }

  fetchRowDetails(e) {
    e.preventDefault()

    let node = this.gridApi.getRowNode($(e.currentTarget).data("node-id"))

    $.get(`/rows/${node.data.row_id}/details`, {
      authenticity_token: $("meta[name=csrf-token]").attr("content")
    }, function(response) {
      $("body").append(response.html)
    })
  }

  _initNodeEvents(node) {
    const grid = this;

    node.addEventListener("mouseEnter", function(e) {
      let actionButtons = grid.actionButtonsTargets.filter(function(el) {
        return $(el).data("node-id") == node.id
      })
      let otherActionButtons = grid.actionButtonsTargets.filter(function(el) {
        return $(el).data("node-id") != node.id
      })
      $(otherActionButtons).addClass("hidden")
      let $actionButtons = $(actionButtons)
      $actionButtons.appendTo(grid.containerTarget)
                    .css("top", (grid.gridApi.getSizesForCurrentTheme().headerHeight + (node.rowIndex + 1) * node.rowHeight) - $actionButtons.height() / 2)
                    .removeClass("hidden")
    })
  }
});

class CustomHeader {
  init(agParams) {

    this.agParams = agParams;
    this.eGui = document.createElement('div');
    let inputNamePrefix = `table[columns_attributes][${this.agParams.column.colDef.colId}]`
    this.eGui.innerHTML = `
      <div class="column-header" data-controller="column-header" data-id="${this.agParams.column.colDef.colId}" data-editable="${this.agParams.column.colDef.suppressMovable ? "" : true}">
        <input type="hidden" name="${inputNamePrefix}[id]" value="${this.agParams.column.colDef.colId || ""}">
        <input type="hidden" name="${inputNamePrefix}[column_order_position]" value="${this.agParams.column.colDef.index || ""}">
        <span data-column-header-target="label">${this.agParams.displayName}</span>
      ${
        this.agParams.column.colDef.suppressMovable ? "" :
        `
        <input data-column-header-target="input" class="input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6" type="text" value="${this.agParams.displayName}" name="${inputNamePrefix}[label]" data-action="keypress.enter->column-header#preventSubmit keyup->column-header#toggleEditInput">
        ${
          this.agParams.column.colDef.deletable ? `
            <span class="ml-1 action-button" style="float: right;" data-column-header-target="actionButton" data-action="click->grid#deleteColumn" data-id="${this.agParams.column.colDef.colId}">
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" height="18">
                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>
            </span>
          ` : ""
        }
        <span class="ml-2 action-button edit" style="float: right;" data-column-header-target="actionButton" data-action="click->column-header#displayEditInput">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" height="18">
            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
        </span>
        `
      }
      </div>
    `;
  }

  getGui() {
    return this.eGui;
  }
}

class CustomCell {
  init(agParams) {
    this.agParams = agParams;
    this.data = this.agParams.getValue();
    this.eGui = document.createElement("div");
    let inputNamePrefix = `project[rows_attributes][${this.agParams.node.rowIndex}][cells_attributes][${this.data.index}]`
    this.eGui.innerHTML = `
      <div class="cell" data-controller="cell" data-config='${JSON.stringify(this.data)}'>
      ${
        `
        <span data-cell-target="label">${this.data.value}</span>
        ${
          this.data.row_id ? `<input type="hidden" name="project[rows_attributes][${this.agParams.node.rowIndex}][id]" value="${this.data.row_id}">` : ""
        }
        ${
          this.data.id ? `<input type="hidden" name="${inputNamePrefix}[id]" value="${this.data.id}">` : ""
        }
        <input type="hidden" name="${inputNamePrefix}[row_id]" value="${this.data.row_id || ""}">
        <input type="hidden" name="${inputNamePrefix}[column_id]" value="${this.data.column_id || ""}">
        ${
          !this.data.has_details ? `
            <input data-cell-target="input" class="input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6" type="text" value="${this.data.value}" name="${inputNamePrefix}[value]" data-action="keypress.enter->cell#preventSubmit keyup->cell#toggleEditInput">
            <span class="ml-2 edit" style="float: right;" data-cell-target="edit" data-action="click->cell#displayEditInput">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" height="18">
                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </span>
          ` : ""
        }
        `
      }
      </div>
      ${
        this.data.index == 0 ? `
          <span class="isolate inline-flex rounded-md shadow-sm absolute left-1/2 transform -translate-x-1/2 hidden" data-grid-target="actionButtons" data-node-id="${this.agParams.node.id}">
            ${
              this.data.has_details ? `
                <button type="button" class="relative inline-flex items-center rounded-l-md bg-white px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" data-action="click->grid#fetchRowDetails" data-node-id="${this.agParams.node.id}">
                  <span class="sr-only">Duplicate</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                  </svg>
                </button>
              ` : ""
            }
            <button type="button" class="relative ${this.data.has_details ? "-ml-px" : "rounded-l-md" } inline-flex items-center bg-white px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" data-action="click->grid#duplicateRow" data-node-id="${this.agParams.node.id}">
              <span class="sr-only">Duplicate</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
              </svg>
            </button>
            <button type="button" class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" data-action="click->grid#deleteRow" data-node-id="${this.agParams.node.id}" data-row-id="${this.data.row_id}">
              <span class="sr-only">Delete</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="size-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>
            </button>
          </span>
        ` : ""
      }
    `;
  }

  getGui() {
    return this.eGui;
  }
}