import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import $ from "jquery"

application.register("project", class extends Controller {
  static targets = ["grid", "emailConfiguration", "contactsSelect", "emailTemplatesSelect", "emailSubject", "textEditor", "sendEmailButton"]
  static values = {
    id: String
  }

  initialize() {
    this._initEvents()
  }

  toggleSendEmailButton(e) {
    let selectedItemsCount = $(this.contactsSelectTarget).val().length;

    if(e.type == "click") selectedItemsCount -= 1

    if(selectedItemsCount > 0) {
      $(this.sendEmailButtonTarget).removeClass("hidden")
    } else {
      $(this.sendEmailButtonTarget).addClass("hidden")
    }
  }

  fillEmailFields() {
    let selectedOption = this.emailTemplatesSelectTarget.options[this.emailTemplatesSelectTarget.selectedIndex]
    this.emailSubjectTarget.value = selectedOption.dataset.subject || ""
    application.getControllerForElementAndIdentifier(this.textEditorTarget, "tiptap-editor").editor.commands.setContent(selectedOption.dataset.content || "")
  }

  sendEmails(e) {
    e.preventDefault();

    const controller = this
    const gridController = application.getControllerForElementAndIdentifier(this.gridTarget, "grid")

    $.post(`/projects/${this.idValue}/send_emails`, {
      authenticity_token: $("meta[name=csrf-token]").attr("content"),
      selected_row_ids: this.selectedRowIds,
      contact_ids: $(this.contactsSelectTarget).val(),
      subject: $(this.emailSubjectTarget).val(),
      content: application.getControllerForElementAndIdentifier(this.textEditorTarget, "tiptap-editor").editor.getHTML()
    }, function(response) {
      application.getControllerForElementAndIdentifier(controller.sendEmailButtonTarget, "loading-button").reset()
      application.getControllerForElementAndIdentifier(window.document.getElementById("notification"), "notification").toggle(`Email${$(this.contactsSelectTarget).val() > 1 ? "s" : ""} sent !`);
      gridController.gridApi.getSelectedRows().forEach(function(o) {
        let node = gridController.gridApi.getRowNode(o.row_id)
        let actionButtons = gridController.actionButtonsTargets.filter(function(el) {
          return $(el).data("node-id") == node.id
        })
        for (const [key, value] of Object.entries(node.data)) {
          if((typeof value == "object")) {
            node.data[key].has_details = true
          }
        }
        $(actionButtons).remove()
        gridController.gridApi.redrawRows({ rowNodes: [node] });
      })
    }).fail(function() {})
  }

  _initEvents() {
    const controller = this;

    $("body").on("grid.selectionChanged", function(e, selectedRows) {
      let selectedRowsCount = selectedRows.length
      controller.selectedRowIds = selectedRows.map(function(row) { return row.row_id })

      if(selectedRowsCount > 0) {
        $(controller.emailConfigurationTarget).removeClass("hidden")
      } else {
        $(controller.emailConfigurationTarget).addClass("hidden")
      }
    })
  }
});