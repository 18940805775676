import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import $ from "jquery"

application.register("loading-button", class extends Controller {
  static targets = ["icon", "text", "spinner"]

  initialize() {
    this.initialText = $(this.textTarget).text()
  }

  loading(e) {
    e.preventDefault();

    $(this.iconTarget).hide();
    $(this.element).prop("disabled", true).addClass("disabled:opacity-50 disabled:pointer-events-none").prepend(`
      <span class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading" data-loading-button-target="spinner"></span>
    `)
    $(this.textTarget).text(this.element.dataset.loadingText)
  }

  reset() {
    $(this.spinnerTarget).remove();
    $(this.iconTarget).show();
    $(this.element).prop("disabled", false).removeClass("disabled:opacity-50 disabled:pointer-events-none")
    $(this.textTarget).text(this.initialText)
  }
})