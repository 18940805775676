import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import { toggle } from 'el-transition'

application.register("topbar", class extends Controller {
  static targets = ["profileMenu"]

  toggleProfileMenu() {
    toggle(this.profileMenuTarget)
  }
});