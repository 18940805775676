import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import { toggle, leave } from 'el-transition'

application.register("modal", class extends Controller {
  static targets = ["backdrop", "panel"]

  initialize() {
    toggle(this.backdropTarget)
    toggle(this.panelTarget)
  }

  connect() {
    window.HSStaticMethods.autoInit(["tooltip"]);
  }

  close() {
    const controller = this

    leave(this.backdropTarget)
    leave(this.panelTarget).then(function() {
      controller.element.remove();
    })
  }
})