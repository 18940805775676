import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import { toggle } from 'el-transition'

application.register("notification", class extends Controller {
  static targets = ["title", "text"]

  toggle(message) {
    const controller = this

    toggle(this.element)
    this.titleTarget.textContent = message || ""

    controller.timeout = setTimeout(function() {
      toggle(controller.element)
    }, 3000)
  }

  close() {
    clearTimeout(this.timeout)
    toggle(this.element)
  }
})