import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Blockquote from '@tiptap/extension-blockquote';

application.register("tiptap-editor", class extends Controller {
  static targets = ["extensionButton"]

  initialize() {
    const controller = this

    this.editor = new Editor({
      element: document.querySelector("#hs-editor-tiptap [data-hs-editor-field]"),
      editorProps: {
        attributes: {
          class: "relative min-h-40 p-3",
        },
      },
      extensions: [
        StarterKit,
        Paragraph.configure({
          HTMLAttributes: {
            class: "text-gray-800"
          }
        }),
        Bold.configure({
          HTMLAttributes: {
            class: "font-bold"
          }
        }),
        Underline,
        Link.configure({
          HTMLAttributes: {
            class: "inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium"
          }
        }),
        BulletList.configure({
          HTMLAttributes: {
            class: "list-disc list-inside text-gray-800"
          }
        }),
        OrderedList.configure({
          HTMLAttributes: {
            class: "list-decimal list-inside text-gray-800"
          }
        }),
        ListItem,
        Blockquote.configure({
          HTMLAttributes: {
            class: "text-gray-800 sm:text-xl"
          }
        })
      ],
      onSelectionUpdate: function() {
        controller._refreshExtensionButtonsState()
      }
    });
    const actions = [
      {
        id: "#hs-editor-tiptap [data-hs-editor-bold]",
        fn: () => this.editor.chain().focus().toggleBold().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-italic]",
        fn: () => this.editor.chain().focus().toggleItalic().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-underline]",
        fn: () => this.editor.chain().focus().toggleUnderline().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-strike]",
        fn: () => this.editor.chain().focus().toggleStrike().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-link]",
        fn: () => {
          const url = window.prompt("URL");
          this.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
        }
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-ol]",
        fn: () => this.editor.chain().focus().toggleOrderedList().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-ul]",
        fn: () => this.editor.chain().focus().toggleBulletList().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-blockquote]",
        fn: () => this.editor.chain().focus().toggleBlockquote().run()
      },
      {
        id: "#hs-editor-tiptap [data-hs-editor-code]",
        fn: () => this.editor.chain().focus().toggleCode().run()
      }
    ];

    this.editor.commands.setContent(this.element.dataset.content)

    actions.forEach(({ id, fn }) => {
      const action = document.querySelector(id);

      if (action === null) return;

      action.addEventListener("click", fn);
    });
  }

  _refreshExtensionButtonsState() {
    const controller = this

    this.extensionButtonTargets.forEach(function(target) {
      target.classList.toggle("bg-gray-200", controller.editor.isActive(target.dataset.extensionName))
    })
  }
});