import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import Clipboard from 'clipboard'

application.register("clipboard", class extends Controller {
  initialize() {
    const controller = this;
    const isToggleTooltip = HSStaticMethods.getClassProperty(this.element,  "--is-toggle-tooltip") === "false" ? false : true;
    const clipboard = new Clipboard(this.element, {
      text: (trigger) => {
        const clipboardText = trigger.dataset.clipboardText;

        if (clipboardText) return clipboardText;

        const clipboardTarget = trigger.dataset.clipboardTarget;

        if (!clipboardTarget) return false;

        const $element = document.querySelector(clipboardTarget);

        if (
          $element.tagName === "SELECT"
          || $element.tagName === "INPUT"
          || $element.tagName === "TEXTARE"
        ) return $element.value
        else return $element.textContent;
      }
    });

    clipboard.on("success", () => {
      const $default = controller.element.querySelector(".js-clipboard-default");
      const $success = controller.element.querySelector(".js-clipboard-success");
      const $successText = controller.element.querySelector(".js-clipboard-success-text");
      const successText = controller.element.dataset.clipboardSuccessText || "";
      const tooltip = controller.element;
      const $tooltip = HSTooltip.getInstance(tooltip, true);

      let oldSuccessText;

      if ($successText) {
        oldSuccessText = $successText.textContent
        $successText.textContent = successText
      }
      if ($default && $success) {
        $default.style.display = "none"
        $success.style.display = "block"
      }
      if (tooltip && isToggleTooltip) HSTooltip.show(tooltip);
      if (tooltip && !isToggleTooltip) $tooltip.element.popperInstance.update();

      setTimeout(function () {
        if ($successText && oldSuccessText) $successText.textContent = oldSuccessText;
        if (tooltip && isToggleTooltip) HSTooltip.hide(tooltip);
        if (tooltip && !isToggleTooltip) $tooltip.element.popperInstance.update();
        if ($default && $success) {
          $success.style.display = "";
          $default.style.display = "";
        }
      }, 800);
    });
  }
})